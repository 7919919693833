// Foundation for Sites by ZURB
// foundation.zurb.com
// Licensed under MIT Open Source

////
/// @group forms
////

$input-group-label-color: $body-font-color !default;
$input-group-label-background: null !default;
$input-group-label-border: null !default;
$input-group-label-padding: null !default;

@mixin foundation-form-prepostfix {
  $height: ($input-font-size * $input-line-height) + (get-side($input-padding, 'top') + get-side($input-padding, 'bottom')) - rem-calc(1);

  .input-group {
    display: flex;
    width: 100%;
    margin-bottom: $form-spacing;
    align-items: stretch;

    label {
      width: 100%;
    }
  }

  %input-group-child {
    margin: 0;
    white-space: nowrap;

    @if not $global-flexbox {
      display: table-cell;
      vertical-align: middle;
    }
  }

  .input-group-label {
    @extend %input-group-child;
    @if input-group-label-padding != null{
      padding: 0 $input-group-label-padding;
    }
    @if input-group-label-border != null{
      border: $input-group-label-border;
    }
    @if input-group-label-background != null{
      background: $input-group-label-background;
    }

    color: $input-group-label-color;

    @if has-value($input-group-label-border) {
      &:first-child {
        border-right: 0;
      }

      &:last-child {
        border-left: 0;
      }
    }
  }

  .input-group-field {
    @extend %input-group-child;
    border-radius: 0;
    flex: 1 1 0px; // sass-lint:disable-line zero-unit
    min-width: 0;
  }

  .input-group-button {
    @extend %input-group-child;
    padding-top: 0;
    padding-bottom: 0;
    text-align: center;
    display: flex;
    flex: 0 0 auto;

    a,
    input,
    button,
    label {
      @extend %input-group-child;
      align-self: stretch;
      height: auto;
      padding-top: 0;
      padding-bottom: 0;
      font-size: $input-font-size;
    }
  }
}
