.carousel{
  position: relative;
  .slide{
    .container{
      display: flex;
      align-items: center;
      justify-content: center;
      height: 550px;

      @include breakpoint(large){
        @include grid-container();
        @include grid(1fr 1fr, 0);
      }
      @include breakpoint(xlarge){
        @include grid(3fr 2fr);
      }
      .content{
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 42px 60px;

        @include breakpoint(medium){
          padding: 42px 120px;
        }
        @include breakpoint(large){
          padding: 42px 0 42px 42px;
        }
        @include breakpoint(xlarge){
          padding: 42px;
        }

        h2{
          text-align: center;
          margin-bottom: 20px;
          line-height: rem-calc(26);
          @include breakpoint(medium){
            padding: 0 50px;
            line-height: rem-calc(30);
          }
          @include breakpoint(large){
            padding: 0 30px;
            font-size: rem-calc(36);
            line-height: rem-calc(40);
          }
        }
        p{
          font-size: rem-calc(18);
          text-align: center;
          @include breakpoint(large){
            padding: 0 30px;
          }
        }

        .button{
          background-color: transparent;
          letter-spacing: 1px;
          border: 2px dotted;
          margin-top: 20px;
        }
      }

      img{
        @include show-for(large);
        @include breakpoint(large){
          padding-right: 42px;
          justify-self: center;
        }
        @include breakpoint(xlarge){
          padding-right: 0;
        }
      }
    }
    &.blue{
      background: linear-gradient(#0d6db7, #2e85c7, #0b538b);
      h2, p{
        color: $white;
      }
      .button:hover{
        background: $yellow;
        color: $black;
        border: 2px solid $yellow;
      }
    }
    &.green{
      background:linear-gradient(#128d4e, #16a75c, #12673b);
      h2, p{
        color: $white;
      }
      .button:hover{
        background: $yellow;
        color: $black;
        border: 2px solid $yellow;
      }
      img{
        align-self: flex-end;
        justify-self: center;
        @include breakpoint(xlarge){

        }
      }
    }
    &.red{
      background:linear-gradient(#bb011d, #e71736, #b60923);
      h2, p{
        color: $white;
      }
      .button:hover{
        background: $white;
        color: $black;
        border: 2px solid $white;
      }
    }
    &.yellow{
      background:linear-gradient(#dfa900, #f9c31b, #dfa900);
      h2, p{
        color: $black;
      }
      .button{
        color: $black;
        border-color: $black;
        &:hover{
          background: $black;
          color: $white;
          border: 2px solid $black;
        }
      }
    }
    
  }

  

  .fade-enter-active,
  .fade-leave-active {
    transition: all 0.2s ease;
    overflow: hidden;
    visibility: visible;
    position: absolute;
    width:100%;
    opacity: 1;
  }

  .fade-enter,
  .fade-leave-to {
    visibility: hidden;
    width:100%;
    opacity: 0;
  }

  .prev, .next {
    @include show-for(large);
    cursor: pointer;
    position: absolute;
    top: calc(50% - 50px);
    color: white;
    //font-weight: bold;
    font-size: rem-calc(40);
    transition: 0.7s ease;
    text-decoration: none;
    user-select: none;
    background-color: rgba($black,0.2);
    width: 50px;
    height: 100px;
    display: flex;
    align-items: center;
   
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    
    @include breakpoint(large){
      top: calc(50% - 100px);
    }
  }

  .next {
    right: 0;
    border-radius: 100px 0 0 100px;
    padding-right: 6px;
    justify-content: flex-end;
  }

  .prev {
    left: 0;
    border-radius: 0 100px 100px 0;
    padding-left: 6px;
    justify-content: flex-start;
  }

  .prev:hover, .next:hover {
    background-color: rgba(0,0,0,0.6);
  }
}