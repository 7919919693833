// Foundation for Sites by ZURB
// foundation.zurb.com
// Licensed under MIT Open Source

$select-background: $white !default;
$select-arrow: '\f0d7' !default;
$select-arrow-color: $dark-gray !default;
$select-radius: $global-radius !default;
$select-arrow-size: $input-font-size !default;

@mixin form-select {
  $height: ($input-font-size * unitless-calc($input-line-height)) + (get-side($input-padding, 'top') + get-side($input-padding, 'bottom')) - rem-calc(1);

  height: $height;
  padding: $input-padding;

  appearance: none;
  border: $input-border;
  border-radius: $select-radius;
  background-color: $select-background;

  font-family: $input-font-family;
  font-size: $input-font-size;
  font-weight: $input-font-weight;
  line-height: $input-line-height;
  color: $input-color;

  @if has-value($input-transition) {
    transition: $input-transition;
  }

  // Focus state
  &:focus {
    outline: none;
    border: $input-border-focus;
    background-color: $input-background-focus;
    box-shadow: $input-shadow-focus;

    @if has-value($input-transition) {
      transition: $input-transition;
    }
  }

  // Disabled state
  &:disabled {
    background-color: $input-background-disabled;
    cursor: $input-cursor-disabled;
  }

  // Hide the dropdown arrow shown in newer IE versions
  &::-ms-expand {
    display: none;
  }

  &[multiple] {
    height: auto;
    background-image: none;
  }
  &:not([multiple]) {
    padding-top: 0;
    padding-bottom: 0;
  }
}

@mixin foundation-form-select {
  .select{
    position: relative;
    overflow: hidden;
    margin: 0 0 $form-spacing;
    width: 100%;
    height: max-content;

    select {
      @include form-select;
    }

    &:after{
      @if $include-fontawesome == true {
        font-family: "Font Awesome 5 Free";
        font-weight: 900;
        content: $select-arrow;
      } @else{
        content: '\25BC';
      }

      color: $select-arrow-color;
      position: absolute;
      right: 0;
      top: 0;
      width: 10%;
      height: 100%;
      pointer-events: none;
      font-size: $select-arrow-size;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
