// Foundation for Sites by ZURB
// foundation.zurb.com
// Licensed under MIT Open Source

////
/// @group forms
////

$input-color: $black !default;
$input-placeholder-color: $medium-gray !default;
$input-font-family: inherit !default;
$input-font-size: rem-calc(16) !default;
$input-font-weight: $global-weight-normal !default;
$input-line-height: $global-lineheight !default;
$input-background: $white !default;
$input-background-focus: $white !default;
$input-background-disabled: $light-gray !default;
$input-border: null !default;
$input-border-focus: null !default;
$input-padding: $form-spacing / 2 !default;
$input-shadow: inset 0 1px 2px rgba($black, 0.1) !default;
$input-shadow-focus: null !default;
$input-cursor-disabled: not-allowed !default;
$input-transition: box-shadow 0.5s, border-color 0.25s ease-in-out !default;
$input-number-spinners: true !default;
$input-radius: $global-radius !default;
$form-button-radius: $global-radius !default;

@mixin form-element {
  $height: ($input-font-size * unitless-calc($input-line-height)) + (get-side($input-padding, 'top') + get-side($input-padding, 'bottom')) - rem-calc(1);

  display: block;
  box-sizing: border-box;
  width: 100%;
  height: $height;
  margin: 0 0 $form-spacing;
  padding: $input-padding;

  @if $input-border != null{
    border: $input-border;
  } @else{
    border: 0;
  }
  
  border-radius: $input-radius;
  background-color: $input-background;
  box-shadow: $input-shadow;

  font-family: $input-font-family;
  font-size: $input-font-size;
  font-weight: $input-font-weight;
  line-height: $input-line-height;
  color: $input-color;

  @if has-value($input-transition) {
    transition: $input-transition;
  }

  // Focus state
  &:focus {
    outline: none;
    background-color: $input-background-focus;

    @if $input-shadow-focus != null{
      box-shadow: $input-shadow-focus;
    }

    @if $input-border-focus != null{
      border: $input-border-focus;
    }

    @if has-value($input-transition) {
      transition: $input-transition;
    }
  }
}

@mixin foundation-form-text {
  // Text inputs
  #{text-inputs()},
  textarea {
    @include form-element;
    appearance: none;
  }

  // Text areas
  textarea {
    max-width: 100%;

    &[rows] {
      height: auto;
    }
  }

  input,
  textarea {
    // Disabled/readonly state
    &:disabled,
    &[readonly] {
      background-color: $input-background-disabled;
      cursor: $input-cursor-disabled;
    }
  }

  // Reset styles on button-like inputs
  [type='submit'],
  [type='button'] {
    appearance: none;
    border-radius: $form-button-radius;
  }

  // Reset Normalize setting content-box to search elements
  input[type='search'] { // sass-lint:disable-line no-qualifying-elements
    box-sizing: border-box;
  }

  // Number input styles
  [type='number'] {
    @if not $input-number-spinners {
      -moz-appearance: textfield; // sass-lint:disable-line no-vendor-prefixes

      &::-webkit-inner-spin-button,
      &::-webkit-outer-spin-button {
        -webkit-appearance: none; // sass-lint:disable-line no-vendor-prefixes
        margin: 0;
      }
    }
  }

  // Placeholder text
  ::placeholder {
    color: $input-placeholder-color;
  }
}
