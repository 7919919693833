// Foundation for Sites by ZURB
// foundation.zurb.com
// Licensed under MIT Open Source

$menu-margin: 0 !default;
$menu-nested-margin: $global-menu-nested-margin !default;
$menu-items-padding: $global-menu-padding !default;
$menu-simple-margin: 1rem !default;
$menu-item-color-active: $white !default;
$menu-item-color-alt-active: $black !default;
$menu-item-background-active: get-color(primary) !default;
$menu-icon-spacing: 0.25rem !default;

/// Creates the base styles for a Menu.
@mixin menu-base {
  padding: 0;
  margin: 0;
  list-style: none;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  
  a,button,
  .button {
    line-height: 1;
    text-decoration: none;
    display: block;
    padding: $menu-items-padding;
  }

  // Reset styles of inner elements
  input,
  select,
  a,
  button {
    margin-bottom: 0;
  }

  button{
    color: $primary-color;
    cursor: pointer;
  }

  input {
    display: inline-block;
  }
}

/// Sets the direction of a Menu.
/// @param {Keyword} $dir [horizontal] - Direction of the Menu. Can be `horizontal` or `vertical`.
@mixin menu-direction($dir: horizontal) {
  @if $dir == horizontal {
      flex-wrap: wrap;
      flex-direction: row;
  }
  @else if $dir == vertical {
    flex-wrap: nowrap;
    flex-direction: column;
  }
  @else {
    @warn 'The direction used for menu-direction() must be horizontal or vertical.';
  }
}

@mixin menu-state-active {
  background: $menu-item-background-active;
  color: $menu-item-color-active;
}

@mixin menu-text {
  padding: $global-menu-padding;
  font-weight: bold;
  line-height: 1;
  color: inherit;
}

@mixin foundation-menu {
  .menu {
    @include menu-base;

    // Default orientation: horizontal
    &, &.horizontal {
      @include menu-direction(horizontal);
    }

    // Vertical orientation modifier
    &.vertical {
      @include menu-direction(vertical);
    }

    // Active state
    .is-active > a {
      @include menu-state-active;
    }

    .menu-text {
      @include menu-text;
    }
  }
}
