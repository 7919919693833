// Foundation for Sites by ZURB
// foundation.zurb.com
// Licensed under MIT Open Source

$breadcrumbs-margin: 0 0 $global-margin 0 !default;
$breadcrumbs-item-font-size: rem-calc(11) !default;
$breadcrumbs-item-color: $primary-color !default;
$breadcrumbs-item-color-current: $black !default;
$breadcrumbs-item-color-disabled: $medium-gray !default;
$breadcrumbs-item-margin: 0.75rem !default;
$breadcrumbs-item-uppercase: true !default;
$breadcrumbs-item-separator: true !default;

// If it exists $breadcrumbs-item-slash is used to build $breadcrumbs-item-separator. See the documentation.
@if variable-exists(breadcrumbs-item-slash) {
  $breadcrumbs-item-separator: $breadcrumbs-item-slash;
}

$breadcrumbs-item-separator-item: '/' !default;
$breadcrumbs-item-separator-color: $medium-gray !default;

// If it exists $breadcrumbs-item-slash-color is used to build $breadcrumbs-item-separator-color. See the documentation.
@if variable-exists(breadcrumbs-item-slash-color) {
  $breadcrumbs-item-separator-color: $breadcrumbs-item-slash-color;
}

/// Adds styles for a breadcrumbs container, along with the styles for the `<li>` and `<a>` elements inside of it.
@mixin breadcrumbs-container {
  display: flex;
  margin: $breadcrumbs-margin;
  list-style: none;

  // Item wrapper
  li {
    font-size: $breadcrumbs-item-font-size;
    color: $breadcrumbs-item-color-current;
    cursor: default;

    @if $breadcrumbs-item-uppercase {
      text-transform: uppercase;
    }

    @if $breadcrumbs-item-separator {
      // Need to escape the backslash
      $separator: $breadcrumbs-item-separator-item;

      &:not(:last-child) {
        &::after {
          position: relative;
          margin: 0 $breadcrumbs-item-margin;
          opacity: 1;
          content: $separator;
          color: $breadcrumbs-item-separator-color;
        }
      }
    }
    @else {
      margin-#{$global-right}: $breadcrumbs-item-margin;
    }
  }

  // Page links
  a {
    color: $breadcrumbs-item-color;

    &:hover {
      text-decoration: underline;
    }
  }
}

@mixin foundation-breadcrumbs {
  .breadcrumbs {
    @include breadcrumbs-container;

    .disabled {
      color: $breadcrumbs-item-color-disabled;
      cursor: not-allowed;
    }
  }
}
