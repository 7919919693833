.main-navigation{
	box-shadow: 0 6px 6px rgba(190,190,190,0.2);
	.top-bar{
		@include show-for(large);
		//box-shadow: 0 6px 6px rgba(190,190,190,0.2);
		.container{
			position: relative;
			padding: 16px 0;
			.logo{
				.cc-logo{
					height: 125px;
					//width: 100px;
				}
			}

			.cart-button{
				background: $light-gray;
				border-radius: 0 0 $global-radius $global-radius;
				position: absolute;
				top: 0;
				right: 0;
				padding: 4px 10px;
				font-size: rem-calc(14);
				font-family: $intro;
			}
		}

		.submenu-parent{
			.submenu{
				margin: -20px 0 0;
				li{
					a{
						padding: rem-calc(8 16 0) ;
					}
				}
			}
		}
	}

	.off-canvas{
		@include hide-for(large);
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
		height: 50px;
		background: $white;

		.site-title{
			margin: 0;
			font-size: rem-calc(20);
		}

		.bm-burger-button{
			top: 16px;
			left: 17px;
			height: 18px;
			width: 22px;
			.line-style{
				height: 17%;
			}
		}
		.bm-menu{
			background: $white;
		}

		.bm-item-list{
			a{
				color: $dark-gray;
				font-family: $intro;

				i{
					margin-left: 10px;
					line-height: 1.6;
				}
			}
		}

		.bm-cross-button{
			right: 11px;
			.bm-cross{
				height: 35px !important;
				width: 5px !important;
				background: $alert-color;
			}
		}
	}
}

body{
	&.bm-overlay {
		.bm-menu {
		  width: 100% !important;
		}
	}
}