.training{
	.overview{

		padding: 60px 40px 20px;
		@include breakpoint(large){
			padding: 80px 80px 0;
		}
		@include breakpoint(xlarge){
			padding: 80px 0 0;
		}

		h1{
			margin-bottom: 50px;
			text-align: center;

			@include breakpoint(large){
				text-align: left;
			}
		}
		p{
			font-size: rem-calc(18);
			line-height: rem-calc(30);
			text-align: center;

			@include breakpoint(large){
				text-align: left;
			}
		}
	}
	.accordion{
		margin: 35px 0;
		padding: 0 20px;

		@include breakpoint(large){
			padding: 0 80px;
		}
		@include breakpoint(xlarge){
			padding: 0;
		}
		
		.accordion-title{
			
			.right{
				@include show-for(large);
				i{
					margin-left: 10px;
				}
			}
		}
		.accordion-content{
			@include breakpoint(large){
				@include grid(1fr 1fr);
			}

			img{
				display: block;
				margin: 0 auto 25px;
			}

			h3{
				font-size: rem-calc(20);
				line-height: rem-calc(26);
			}
		}
	}

	footer{
		padding: 0 40px 60px;
		@include breakpoint(large){
			padding: 0 80px 80px;
		}
		@include breakpoint(xlarge){
			padding: 0 0 80px;
		}
	}
}