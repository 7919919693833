.elearn{
	.overview{
		background: $white;
		border-radius: 10px;
		margin: 50px 0 30px;
		padding: 30px 50px;

		@include breakpoint(large){
			@include grid(1fr 1fr);
			align-items: center;
		}

		.logo{
			padding: 30px 50px;
		}
	}

	.begin{
		display: flex;
		justify-content: flex-end;
		margin-bottom: 30px;

		.button{
			background: $yellow;
			color: $black;

			&:hover{
				background: $white;
			}
		}
	}
}