@font-face {
    font-family: 'Intro';
    src: url('/fonts/intro.eot');
    src: url('/fonts/intro.eot?#iefix') format('embedded-opentype'),
         url('/fonts/intro.woff') format('woff'),
         url('/fonts/intro.ttf') format('truetype'),
         url('/fonts/fonts/intro.svg#intro_regular') format('svg');
}
// 2. Custom Variables:
$sans:'Open Sans', sans-serif;
$intro: 'Intro', $sans, sans-serif;

//Font Awesome
$fa-font-path: "/fonts";
@import "~@fortawesome/fontawesome-free/scss/fontawesome.scss";
@import "~@fortawesome/fontawesome-free/scss/regular";
@import "~@fortawesome/fontawesome-free/scss/solid";
@import "~@fortawesome/fontawesome-free/scss/brands";