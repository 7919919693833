// Foundation for Sites by ZURB
// foundation.zurb.com
// Licensed under MIT Open Source

$topbar-padding: 0 !default;
$topbar-lineheight: 1 !default;
$topbar-background: $light-gray !default;
$topbar-submenu-background: $topbar-background !default;
$topbar-input-width: 200px !default;
$topbar-link-font: $body-font-family !default;
$topbar-link-transform: uppercase !default;
$topbar-link-color: $primary-color !default;
$topbar-link-color-hover: scale-color($primary-color, $lightness: 20%) !default;
$topbar-link-color-active: scale-color($primary-color, $lightness: 20%) !default;
$topbar-link-weight: $global-weight-bold !default;
$topbar-font-size: rem-calc(18) !default;

/// Adds styles for a top bar container.
@mixin top-bar {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  padding: $topbar-padding;
  background-color: $topbar-background;

  ul.menu > li > a, ul.menu li > button{
    font-family: $topbar-link-font;
    text-transform: $topbar-link-transform;
    color: $topbar-link-color;
    font-weight: $topbar-link-weight;
    font-size: $topbar-font-size;
    line-height: $topbar-lineheight;
    transition: $global-transition;

    &:hover{
      color: $topbar-link-color-hover;
    }

    &.is-active{
      color: $topbar-link-color-active;
    }
  }

  .button, button{
    margin-bottom: 0;
    outline: none;
  }
}

@mixin foundation-top-bar {
  // Top bar container
  .top-bar, .top-bar .container {
    @include top-bar;
  }
}
