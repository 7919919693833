.page-change-cycle-overview{
	.container{
		padding: 60px 40px 20px;
		@include breakpoint(large){
			padding: 80px 80px 0;
		}
		@include breakpoint(xlarge){
			padding: 80px 0 0;
		}
		h1, p{
			text-align: center;

			@include breakpoint(large){
				text-align: left;
			}
		}

		p{
			font-size: rem-calc(18);
			line-height: rem-calc(30);
			margin-bottom: 20px;
		}
	}
	.overview{
		text-align: center;
		//padding-bottom: 80px;

		@include breakpoint(large){
			@include grid-container();
			@include grid(1fr 1fr);
			margin-top: 40px;
			text-align: left;
			padding: 0 80px;
		}

		@include breakpoint(xlarge){
			padding: 0;
		}

		aside{
			img{
				margin: 0 auto 30px;
				@include breakpoint(large){
					margin: 0 auto;
				}
			}
		}
	}

	.accordion{
		@include breakpoint(xlarge){
			padding: 100px 0 0;
		}
		.accordion-instructions{
			margin-bottom: 20px;
			text-align: center;
		}
		.accordion-title{
			padding: 10px;
		}
		.accordion-content{
			&.dark-blue{
				padding: 0;
			}
		}
	}

	.accordion-description{
		@include grid-container();
		padding: 50px;
		font-size: rem-calc(18);
		line-height: rem-calc(30);
		@include breakpoint(large){
			padding: 80px;
		}
		@include breakpoint(xlarge){
			padding: 50px 0 80px;
		}
	}

	.video{
		//padding: 30px 0;
		//margin-top: 100px;
		//background: $light-gray;
		/*.video-container{
			@include grid-container();
		}
		h1{
			text-align: center;
			margin: 30px 0;
		}*/
		video{
			width: 100%;
		}
	}
}