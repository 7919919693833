.page-the-elocator{
	padding: $global-mobile-padding;
	@include breakpoint(large){
		padding: $global-page-padding;
	}

	.content{
		@include breakpoint(large){
			@include grid(1fr 1fr);
		}

		main{
			h1{
				color: $primary-color;
				line-height: rem-calc(28);
				margin-bottom: 30px;
				@include breakpoint(large){
					line-height: rem-calc(34);
				}
			}

			.purchase-form{
				margin-top: 30px;
			}

			.coupon{
				@include breakpoint(medium down){
					margin-top: 50px;
				}
				h4, h5, h6{
					margin: 0;
				}
				span{
					font-family: $sans;
					font-weight: bold;
					color: $primary-color;
				}
			}

			.cta{
				@include breakpoint(medium down){
					margin-top: 30px;
				}
				@include breakpoint(large){
					position: relative;
					margin: 62px 0;
				}
				img{
					@include show-for(large);
					position: absolute;
					right: -80px;
					top: -53px;
				}
			}
		}

		aside{
			@include breakpoint(medium down){
				margin-top: 50px;
			}
			p{
				text-align: center;
			}
			.figcaption{
				margin-top: 30px;
				a{
					font-weight: bold;
				}
			}
		}
	}
}

.elocator-bar{
	background: $primary-color;

	.container{
		@include breakpoint(large){
			display: flex;
			justify-content: space-between;
			align-items: center;
			height: 50px;
		}
	}
	
	a{
		color: $white;
		
		@include breakpoint(large){
			height: 50px;
			display: flex;
		}

		span{
			display: flex;
			align-items: center;
			justify-content: center;
		}
		
		.tag{
			background: $yellow;
			color: $black;
			text-transform: uppercase;
			padding: 0 36px 0 20px;
			font-family: $intro;
			font-size: rem-calc(18);

			@include breakpoint(large){
				height: 50px;
				position: relative;

				&::before, &::after{
				  content: '';
				  width: 0; 
				  height: 0;
				  border-left: 30px solid transparent;
				  position: absolute;
				  right: 0;
				}

				&::before{ 
				  border-top: 30px solid $primary-color;
				  border-bottom: 30px solid transparent;
				  top: 0;
				}

				&::after{
				  border-top: 30px solid transparent;
				  border-bottom: 30px solid $primary-color;
				  bottom: 0;
				}
			}
		}

		.title{

			strong{
				font-size: rem-calc(26);
				font-weight: 900;
				margin: 0 10px;
			}
		}

		.cta{
			font-family: $intro;
			font-size: rem-calc(18);

			i{
				color: $yellow;
				margin-left: 10px;
			}

			&:hover{
				i{
					color: $white;
				}
			}
		}
	}
}