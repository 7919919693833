////
/// @group accordion-menu
////

$accordionmenu-padding: $global-menu-padding !default;
$accordionmenu-submenu-padding: $accordionmenu-padding !default;
$accordionmenu-arrow-color: $primary-color !default;
$accordionmenu-item-background: null !default;
$accordionmenu-border: null !default;
$accordionmenu-submenu-toggle-background: null !default;
$accordion-submenu-toggle-border: $accordionmenu-border !default;
$accordionmenu-submenu-toggle-width: 40px !default;
$accordionmenu-submenu-toggle-height: $accordionmenu-submenu-toggle-width !default;
$accordionmenu-arrow-size: 6px !default;

@mixin foundation-accordion-menu {

  .accordion-menu {
    @if $accordionmenu-border {
      border-bottom: $accordionmenu-border;
    }

    li {
      @if $accordionmenu-border {
        border-top: $accordionmenu-border;
        border-right: $accordionmenu-border;
        border-left: $accordionmenu-border;
      }
      width: 100%;
    }

    a {
      @if $accordionmenu-item-background {
        background: $accordionmenu-item-background;
      }
      padding: $accordionmenu-padding;
    }

    .title{
      display: flex;
      justify-content: space-between;
    }

    .is-accordion-submenu {
      list-style: none;
      a{
        padding: $accordionmenu-submenu-padding;
      }
    }

  }

}
