$grid-container-width: 1200px !default;
$grid-columns: 1fr !default;
$grid-column-gap: rem-calc(30) !default;
$grid-column-padding: rem-calc(15) !default;

@mixin grid-container(
  $width: $grid-container-width,
) {
  width: 100%;
  max-width: $width;
  margin-left: auto;
  margin-right: auto;
}

@mixin grid(
  $columns: $grid-columns,
  $gutter: $grid-column-gap,
) {
  display: grid;
  grid-template-columns: $columns;
  grid-column-gap: $gutter;
}

@mixin c-grid-classes {

  // Grid Container
  .container {
    @include grid-container;
  }

  // Grid Container with Grid
  .grid-row {
    @include grid-container;
    @include grid;
  }
}