.accordion{
  .accordion-item{
    margin-bottom: 4px;
    .accordion-title{
      font-size: rem-calc(14);

      @include breakpoint(large){
        font-size: $accordion-title-font-size;
      }
      &.red{
        background: #e91d38;
      }
      &.yellow{
        background: #fbd617;
        color: $black;
      }
      &.green{
        background: #149351;
      }
      &.blue{
        background: #39a5dd;
      }
      &.dark-blue{
        background: $primary-color;
      }
      &.pink{
        background: #c54086;
      }
    }
    .accordion-content{
      &.red{
        background: rgba($black, 0.05);
      }
      &.yellow{
        background: #fff7cf;
        color: $black;
      }
      &.green{
        background: #dbf0dd;
      }
      &.blue{
        background: #daeef9;
      }
    }
  }
}