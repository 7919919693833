// Foundation for Sites by ZURB
// foundation.zurb.com
// Licensed under MIT Open Source

$dropdownmenu-arrows: true !default;
$dropdownmenu-arrow-color: $anchor-color !default;
$dropdownmenu-arrow-size: 6px !default;
$dropdownmenu-arrow-padding: 1.5rem !default;
$dropdownmenu-min-width: 200px !default;
$dropdownmenu-item-color: $primary-color !default;
$dropdownmenu-background: null !default;
$dropdownmenu-submenu-background: $white !default;
$dropdownmenu-padding: $global-menu-padding !default;
$dropdownmenu-nested-margin: 0 !default;
$dropdownmenu-submenu-padding: $dropdownmenu-padding !default;
$dropdownmenu-border: null !default;
$dropdownmenu-border-width: nth($dropdownmenu-border, 1);
$dropdown-menu-item-color-hover: scale-color($primary-color, $lightness: 20%) !default;
$dropdown-menu-item-color-active: get-color(primary) !default;
$dropdown-menu-item-background-active: transparent !default;
$dropdownmenu-arrow: '\f0d7' !default;
$dropdown-menu-font-size: $global-font-size !default;

@mixin dropdown-menu-direction($dir: horizontal) {
  @if $dir == horizontal {
    > li.opens-left { // sass-lint:disable-line no-qualifying-elements
      > .is-dropdown-submenu {
        top: 100%;
        right: 0;
        left: auto;
      }
    }

    > li.opens-right { // sass-lint:disable-line no-qualifying-elements
      > .is-dropdown-submenu {
        top: 100%;
        right: auto;
        left: 0;
      }
    }

    @if $dropdownmenu-arrows {
      > li.submenu-parent > a { // sass-lint:disable-line no-qualifying-elements
        position: relative;
        padding-right: $dropdownmenu-arrow-padding;
      }

      > li.submenu-parent > a::after { // sass-lint:disable-line no-qualifying-elements
        //@include css-triangle($dropdownmenu-arrow-size, $dropdownmenu-arrow-color, down);
        right: 5px;
        left: auto;
        margin-top: -1 * ($dropdownmenu-arrow-size / 2);
        content: $dropdownmenu-arrow;
        @if $include-fontawesome == true {
          font-family: "Font Awesome 5 Free";
          font-weight: 900;
        }
      }
    }
  }
  @else if $dir == vertical {
    > li {
      .submenu {
        top: 0;
      }

      &.opens-left {
        > .submenu {
          top: 0;
          right: 100%;
          left: auto;
        }
      }

      &.opens-right {
        > .submenu {
          right: auto;
          left: 100%;
        }
      }

      @if $dropdownmenu-arrows {
        content: $dropdownmenu-arrow;
        @if $include-fontawesome == true {
          font-family: "Font Awesome 5 Free";
          font-weight: 900;
        }
      }
    }
  }
  @else {
    @warn 'The direction used for dropdown-menu-direction() must be horizontal or vertical.';
  }
}

@mixin foundation-dropdown-menu {
  .dropdown.menu {
    @include dropdown-menu-direction(horizontal);

    // Top-level item
    > li > a {
      background: $dropdownmenu-background;
      padding: $dropdownmenu-padding;
    }

    // Top-level item active state
    > li.is-active > a {
      background: $dropdown-menu-item-background-active;
      color: $dropdown-menu-item-color-active;
    }

    &.vertical {
      @include dropdown-menu-direction(vertical);
    }

  }

  .submenu-parent{
    @if $dropdownmenu-arrows {
      > a{
        position: relative;
        &:after{
          margin-left: 5px;
          content: $dropdownmenu-arrow;
          @if $include-fontawesome == true {
            font-family: "Font Awesome 5 Free";
          }
        }
      }
    }

    &.opens-left > .is-dropdown-submenu {
      right: 100%;
      left: auto;
    }

    &.opens-right > .is-dropdown-submenu {
      right: auto;
      left: 100%;
    }
  }

  .submenu {
    position: absolute;
    z-index: 1;
    list-style: none;
    display: none;
    min-width: $dropdownmenu-min-width;
    background: $dropdownmenu-submenu-background;

    @if $dropdownmenu-border != null{
      border: $dropdownmenu-border;
    }

    .dropdown & a, .dropdown & button {
      padding: $dropdownmenu-submenu-padding;
      color: $dropdownmenu-item-color;
      font-size: $dropdown-menu-font-size;
      font-family: $topbar-link-font;

      &:hover{
        color: $dropdown-menu-item-color-hover;
      }
    }

    @if (type-of($dropdownmenu-border-width) == 'number') {
      .submenu {
        margin-top: (-$dropdownmenu-border-width);
      }
    }

    > li {
      width: 100%;
    }
  }
  .submenu-parent, .submenu{
    &:hover{
      .submenu{
        display: block;
      }
    }
  }
}
