.sidebar{
	background: $black;
	color: $white;
	padding: 30px 0 30px 30px;

	a, button{
		color: $white;
		outline: none;
		&:hover{
			background: $dark-gray;
		}

		i{
			margin-right: 15px;
		}
	}
}