.single-product{
	padding: $global-mobile-padding;
	@include breakpoint(large){
		@include grid(3fr 2fr);
		padding: 80px;
	}

	@include breakpoint(xlarge){
		padding: $global-page-padding;
	}

	.details{
		@include breakpoint(large){
			padding-right: 50px;
		}
		@include breakpoint(xlarge){
			padding-right: 150px;
		}
		.title{
			text-transform: uppercase;
			text-align: center;

			@include breakpoint(medium){
				font-size: rem-calc(26);
				text-align: left;
			}
		}

		.price{
			text-align: center;

			@include breakpoint(large){
				text-align: left;
			}
		}

		form{
			h4{
				font-size: rem-calc(18);
			}
			label{
				font-family: $intro;
				text-transform: uppercase;
				min-width: max-content;
				max-width: max-content;
			}
			.option, .language{
				display: flex;
				align-items: center;
				margin-bottom: 16px;
			}

			.option{
				margin: 20px 0;

				@include breakpoint(medium){
					//margin: 0;
				}

				label{
					margin-right: 10px;
				}
				.select{
					margin-bottom: 0;
				}
			}
			.quantities{
				@include breakpoint(medium){
					padding-right: 50px;
					.english{
						width: 46.5%;
					}
				}

				h4{
					text-align: center;

					@include breakpoint(medium){
						text-align: left;
					}
				}
				.language{
					justify-content: space-between;
					padding: 0 40px;

					@include breakpoint(medium){
						padding: 0;
					}
					label{
						margin-right: 10px;
						@include breakpoint(medium){
							margin-right: 0;
						}
					}
				}
				
				input{
					width: 100px;
					margin-bottom: 0;
				}
				

				.more-languages{
					.dropdown-title{
						display: block;
						margin: 40px 0 0;
						text-transform: uppercase;
						font-family: $intro;
						text-align: center;

						@include breakpoint(medium){
							text-align: left;
							margin: 0 0 16px;
						}
					}
					.dropdown-pane{
						@include breakpoint(medium){
							@include grid(1fr 1fr, rem-calc(14));
						}
					}
				}
			}

			.button{
				border-color: $green;
				background: $green;
				display: block;
				margin: 40px auto;
				width: max-content;

				&:hover{
					background: $primary-color;
					border-color: $primary-color;
				}

				@include breakpoint(medium){
					margin: 20px 0 0;
				}
			}
		}
	}

	.gallery{
		img{
			display: block;
			margin: 0 auto 10px;
		}
	}

	footer{
		margin-top: 40px;
		.back-button{
			color: $black;
			text-transform: uppercase;
			font-family: $intro;
			display: flex;
			align-items: center;

			&:hover{
				color: $primary-color;
			}

			i{
				margin-right: 5px;
			}
		}
	}
}