/**
 * Foundation for Sites by ZURB
 * Version 6.6.1
 * foundation.zurb.com
 * Licensed under MIT Open Source
 */

// --- Dependencies ---
@import "~normalize.css/normalize.css";

// --- Components ---
// Global styles
@import 'global';
//@import 'settings/settings';

@import 'forms/forms';
@import 'typography/typography';

//Generic components
@import 'components/accordion';
@import 'components/accordion-menu';
@import 'components/callout';
@import 'components/grid';
@import 'components/button';
@import 'components/switch';
@import 'components/table';
@import 'components/breadcrumbs';
@import 'components/dropdown';
@import 'components/dropdown-menu';
@import 'components/pagination';
@import 'components/responsive-embed';
@import 'components/menu';
@import 'components/top-bar';

//Dashboard
@import 'dashboard/dashboard';

// Helpers
@import 'components/visibility';