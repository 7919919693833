.dashboard{
	min-height: 100vh;
	@import 'sidebar';
	@import 'main';
	@include grid(3fr 9fr, 0);
}

.admin-panel{
	th.center, td.center{
		text-align: center;
	}
	.delete-circle{
		outline: none;
		color: $alert-color;
		margin: 0 auto;
		display: block;
	}
}