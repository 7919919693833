// Foundation for Sites by ZURB
// foundation.zurb.com
// Licensed under MIT Open Source

////
/// @group callout
////

$callout-background: transparent !default;
$callout-border: 0 !default;
$callout-margin: 0 0 1rem 0 !default;
$callout-sizes: (
  small: 0.5rem,
  default: 1rem,
  large: 3rem,
) !default;

$callout-font-color: $body-font-color !default;
$callout-font-color-alt: $body-background !default;
$callout-radius: $global-radius !default;

/// Adds basic styles for a callout, including padding and margin.
@mixin callout-base() {
  position: relative;
  margin: $callout-margin;
  padding: map-get($callout-sizes, default);
  border: $callout-border;
  border-radius: $callout-radius;
  color: $callout-font-color;
  font-size: rem-calc(18);

  // Respect the padding, fool.
  > :first-child {
    margin-top: 0;
  }

  > :last-child {
    margin-bottom: 0;
  }

  .button{
    margin: 0 0 0 10px;
  }
}

@mixin callout-size($padding) {
  padding-top: $padding;
  padding-right: $padding;
  padding-bottom: $padding;
  padding-left: $padding;
}


/// Adds styles for a callout.
/// @param {Color} $color [$callout-background] - Color to use.
@mixin callout() {
  @include callout-base;
  //@include callout-style($color);
}

@mixin foundation-callout {
  .callout {
    @include callout;

    @each $size, $padding in map-remove($callout-sizes, default) {
      &.#{$size} {
        @include callout-size($padding);
      }
    }
  }
}
