body.elearn{
	height: 100vh;
	display: flex;
	flex-direction: column;
	justify-content: space-between;

	.elearning-navigation{
		padding: 30px 0;
		box-shadow: 0 6px 6px rgba(190,190,190,0.2);

		.logo{
			img{
				width: 220px;
			}
		}
	}

	.elearning-content{
		padding: 60px 0;
	}

	.elearning-footer{
		box-shadow: inset 0 20px 20px -20px rgba(190,190,190,0.8);
		.container{
			display: flex;
			align-items: center;
			padding: 40px 15px;

			a, p{
				color: $black;
			}
			a:hover{
				color: $primary-color;
			}

			.link{
				font-size: rem-calc(24);
				font-weight: bold;
				margin-left: 20px;
			}
			.copyright{
				font-size: rem-calc(20);
				margin: 0 20px;
			}
			.privacy-policy{
				font-size: rem-calc(20);
				font-weight: bold;
			}
		}
	}
}