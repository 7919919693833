// Foundation for Sites by ZURB
// foundation.zurb.com
// Licensed under MIT Open Source

$dropdown-padding: 1rem !default;
$dropdown-background: $body-background !default;
$dropdown-border: 0 !default;
$dropdown-font-size: 1rem !default;
$dropdown-width: 300px !default;
$dropdown-radius: $global-radius !default;
$dropdown-sizes: (
  tiny: 100px,
  small: 200px,
  large: 400px,
  full: 100%,
) !default;

@mixin dropdown-container {
  width: $dropdown-width;
  padding: $dropdown-padding;
  border: $dropdown-border;
  border-radius: $dropdown-radius;
  background-color: $dropdown-background;
  font-size: $dropdown-font-size;
}

@mixin foundation-dropdown {
  .dropdown-pane {
    @include dropdown-container;
  }

  @each $name, $size in $dropdown-sizes {
    .dropdown-pane {
      &.#{$name} {
        width: $size;
      }
    }
  }
}
