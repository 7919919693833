.elearn{
	.content{
		background: $green;
		height: 100%;
		display: flex;
		align-items: center;
	}
	.login{
		display: block;
		margin: 0 auto;
		width: max-content;

		h1{
			color: $white;
		}
	}
	.login-form{
		max-width: 738px;
		background: $white;
		padding: 30px 30px 15px;
		border-radius: 10px;
	}

	.callout{
		background: $white;
		display: flex;
		align-items: center;

		i{
			font-size: rem-calc(50);
			color: $alert-color;
		}
		ul{
			list-style: none;
			margin-bottom: 0;
			height: max-content;
			li{
				font-weight: bold;
				color: $black;
			}
		}
	}
}