.about-us{
	.company{
		background: linear-gradient(#0d6db7, #2e85c7, #0b538b);
    	padding: $global-mobile-padding;
    	text-align: center;
	    color: $white;

	    @include breakpoint(large){
	    	padding: 80px;
	    	font-size: rem-calc(24);
	    	line-height: rem-calc(36);
	    	text-align: left;
	    }

	    @include breakpoint(xlarge){
	    	padding: 55px 0px 45px 0px;
	    }
	}
	.bios{
		padding: $global-mobile-padding;
		h2{
			text-align: center;

			@include breakpoint(large){
				text-align: left;
			}
		}
		img{
			display: block;
			margin: 22px auto 30px;
		}
		p{
			text-align: center;
		}
		@include breakpoint(large){
			padding: 50px 80px;
			img{
				display: inline-block;
				float: right;
				margin: 0 0 0 30px;
			}
			p{
				text-align: left;
			}
		}

		@include breakpoint(xlarge){
			padding: 50px 0;
		}
	}
}