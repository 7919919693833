// Foundation for Sites by ZURB
// foundation.zurb.com
// Licensed under MIT Open Source

$accordion-title-font-size: rem-calc(12) !default;
$accordion-title-case: uppercase !default;
$accordion-title-weight: $global-weight-bold !default;
$accordion-title-padding: rem-calc(16) !default;
$accordion-title-color: $primary-color !default;
$accordion-title-background: $white !default;
$accordion-title-background-hover: $light-gray !default;
$accordion-content-background: $white !default;
$accordion-content-border: 1px solid $light-gray !default;
$accordion-content-color: $body-font-color !default;
$accordion-content-padding: 1rem !default;

/// Adds styles for an accordion container. Apply this to the same element that gets `data-accordion`.
@mixin accordion-container () {
  list-style-type: none;

  &[disabled] {
    .accordion-title {
      cursor: not-allowed;
    }
  }
}

/// Adds styles for the title of an accordion item. Apply this to the link within an accordion item.
@mixin accordion-title (
  $padding: $accordion-title-padding,
  $font-size: $accordion-title-font-size,
  $case: $accordion-title-case,
  $weight: $accordion-title-weight,
  $color: $accordion-title-color,
  $border: $accordion-content-border,
  $background: $accordion-title-background,
  $background-hover: $accordion-title-background-hover,
) {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: $padding;
  border: $border;
  border-bottom: 0;
  font-size: $font-size;
  line-height: 1;
  color: $color;
  text-transform: $case;
  font-weight: $weight;
  background: $background;
  transition: $global-transition;

  //Disable selection text
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
       -moz-user-select: none; /* Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */

  :last-child:not(.is-active) > & {
    border-bottom: $border;
  }

  &:hover,
  &:focus {
    color: $color;
    background-color: $background-hover;
  }

}

/// Adds styles for accordion content. Apply this to the content pane below an accordion item's title.
@mixin accordion-content (
  $padding: $accordion-content-padding,
  $border: $accordion-content-border,
  $background: $accordion-content-background,
  $color: $accordion-content-color
) {
  padding: $padding;
  border: $border;
  border-bottom: 0;
  background-color: $background;
  color: $color;

  :last-child > &:last-child {
    border-bottom: $border;
  }
}

@mixin foundation-accordion {
  .accordion {
    @include accordion-container;
  }

  .accordion-title {
    @include accordion-title;
  }

  .accordion-content {
    @include accordion-content;
  }
}
