// Foundation for Sites by ZURB
// foundation.zurb.com
// Licensed under MIT Open Source

@import 'util/util';

$global-font-size: 100% !default;
$global-width: rem-calc(1200) !default;
$global-lineheight: 1.5 !default;
$global-page-padding: 50px 0 !default;
$include-fontawesome: false !default;

/// Colors used for buttons, callouts, links, etc. There must always be a color called `primary`.
$primary-color: #1779ba !default;
$secondary-color: #767676 !default;
$success-color: #3adb76 !default;
$warning-color: #ffae00 !default;
$alert-color: #cc4b37 !default;
$light-gray: #e6e6e6 !default;
$medium-gray: #cacaca !default;
$dark-gray: #8a8a8a !default;
$black: #0a0a0a !default;
$white: #fefefe !default;

// Body Defaults
$body-background: $white !default;
$body-font-color: $black !default;
$body-font-family: 'Helvetica Neue', Helvetica, Roboto, Arial, sans-serif !default;
$body-antialiased: true !default;

//Global Defaults
$global-margin: 1rem !default;
$global-padding: 1rem !default;
$global-position: 1rem !default;
$global-weight-normal: normal !default;
$global-weight-bold: bold !default;
$global-radius: 0 !default;
$global-transition: 0.3s !default;
$global-menu-padding: 0.7rem 1rem !default;
$global-menu-nested-margin: 1rem !default;
$global-flexbox: true !default;
$global-prototype-breakpoints: false !default;
$global-button-cursor: auto !default;

@mixin foundation-global-styles {

  html {
    box-sizing: border-box;
    font-size: $global-font-size;
  }

  // Set box-sizing globally to handle padding and border widths
  *,
  *::before,
  *::after {
    box-sizing: inherit;
  }

  // Default body styles
  body {
    margin: 0;
    padding: 0;

    background: $body-background;

    font-family: $body-font-family;
    font-weight: $global-weight-normal;
    line-height: $global-lineheight;
    color: $body-font-color;

    @if ($body-antialiased) {
      -webkit-font-smoothing: antialiased; // sass-lint:disable-line no-vendor-prefixes
      -moz-osx-font-smoothing: grayscale; // sass-lint:disable-line no-vendor-prefixes
    }
  }

  img {
    // Get rid of gap under images by making them display: inline-block; by default
    display: inline-block;
    vertical-align: middle;

    // Grid defaults to get images and embeds to work properly
    max-width: 100%;
    height: auto;
    -ms-interpolation-mode: bicubic;
  }

  // Make sure textarea takes on height automatically
  textarea {
    height: auto;
    min-height: 50px;
    border-radius: $global-radius;
  }

  // Make select elements are 100% width by default
  select {
    box-sizing: border-box;
    width: 100%;
    border-radius: $global-radius;
  }

  // Reset <button> styles created by most browsers
  button {
    padding: 0;
    appearance: none;
    border: 0;
    border-radius: $global-radius;
    background: transparent;
    line-height: 1;
    cursor: $global-button-cursor;
  }

  // Make reset inherit font-family instead of settings sans-serif
  button,
  input,
  optgroup,
  select,
  textarea {
    font-family: inherit;
  }

  [v-cloak] {display: none}
}
