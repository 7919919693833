.all-products{
	padding: $global-mobile-padding;

	@include breakpoint(medium){
		@include grid(1fr 1fr);
	}

	@include breakpoint(large){
		@include grid(1fr 1fr 1fr 1fr);
		padding: $global-page-padding;
	}

	.product{
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 50px;

		.featured-image{
			img{
				height: 230px;
				object-fit: contain;
			}
		}
		.title{
			color: $black;
			text-transform: uppercase;
			font-family: $intro;
			text-align: center;
			margin: 20px 0;
			line-height: rem-calc(20);
		}
		.button{
			background: transparent;
			border: 2px dotted $black;
			color: $black;
			padding: 10px 16px;
		}

		&:hover{
			.button{
				border-color: $green;
				background: $green;
				color: $white;
			}
		}
	}
}