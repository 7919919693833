.cart{
	padding: $global-page-padding;
}
.cart-table{
	thead{
		.product-image i{
			display: block;
			text-align: center;
		}
		.center{
			text-align: center;	
		}
	}
	tbody{
		.product-image{
			img{
				height: 75px;
				display: block;
				margin: 0 auto;
			}
		}
		.description{
			h2{
				font-size: rem-calc(16);
			}
			.options{
				margin: 0;
				strong{
					text-align: center;
				}
			}
			.languages{
				display: flex;

				h3{
					font-size: rem-calc(14);
					margin: 4px 0 0;
				}
				ul{
					margin: 0 0 0 32px;
					list-style-type: square;
				}
			}
		}
		.center{
			text-align: center;	
		}
		.button-remove{
			cursor: pointer;
			display: block;
			margin: 0 auto;

			&:hover{
				color: $alert-color;
			}
		}
	}
}

.cart-totals{
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	padding-right: 50px;
	
	.button{
		background: $green;
		border: 0px;

		&:hover{
			background: $primary-color;
		}
	}
}
