.our-clients{
	background: linear-gradient(#f9c31b, #dfa900);
	padding: $global-mobile-padding;
	box-shadow: inset 0 20px 20px -20px #ca9a05, inset 0 -20px 20px -20px #ca9a05;

    @include breakpoint(large){
        padding: 80px;
    }
	@include breakpoint(xlarge){
		padding: $global-page-padding;
	}

    h1{
        text-align: center;

        @include breakpoint(large){
            text-align: left;
        }
    }
	ul{
    	list-style: none;
    	margin-left: 0;

    	@include breakpoint(medium){
    		-webkit-column-count: 2;
    		-moz-column-count: 2;
    		column-count: 2;
    	}
    	@include breakpoint(large){
    		-webkit-column-count: 3;
    		-moz-column-count: 3;
    		column-count: 3;
    	}
	}
}