.referral{
	@include grid-container();
	margin-top: 80px;

	.referral-form{
		.fieldset{
			align-items: flex-end;
			label{
				font-weight: bold;
				margin-right: rem-calc(16);
			}
			button[type=submit]{
				margin: 0;
			}
		}
	}
}