.page-home{
	.intro{
		padding: $global-mobile-padding;

		@include breakpoint(large){
			padding: 80px;
		}

		@include breakpoint(xlarge){
			padding: 120px 0;
		}

		h1{
			font-family: $sans;
			font-weight: normal;
			color: $dark-gray;
			line-height: rem-calc(40);
			text-align: center;
			margin: 0;

			strong{
				font-weight: 600;
			}

			@include breakpoint(medium only){
				line-height: rem-calc(30);
				font-size: rem-calc(22);
			}

			@include breakpoint(large){
				line-height: rem-calc(50);
				text-align: left;
			}
		}
	}

	.schedule{
		padding: $global-mobile-padding;

		@include breakpoint(xlarge){
			padding: 120px 0;
		}

		@include breakpoint(xlarge){
			padding: 125px 0;
		}

		h1{
			text-align: center;
			line-height: rem-calc(26);

			@include breakpoint(medium){
				text-align: left;
				line-height: rem-calc(36);
			}
			@include breakpoint(large){
				font-size: rem-calc(32);
				margin-bottom: 20px;
			}
		}
		h2.dates{
			font-family: $sans;
			font-style: italic;
			font-weight: 600;
			text-align: center;
			font-size: rem-calc(18);

			@include breakpoint(medium down){
				margin-bottom: 27px;
			}

			@include breakpoint(medium){
				font-size: rem-calc(24);
				text-align: left;
				line-height: rem-calc(28);
			}
		}
		p{
			font-size: rem-calc(20);
			line-height: rem-calc(32);
			text-align: center;

			@include breakpoint(medium){
				text-align: left;
				margin: 40px 0;
			}
		}

		.button{
			display: block;
			width: max-content;
			margin: 0 auto;

			@include breakpoint(medium){
				margin: 0;
			}
		}
		aside{
			@include show-for(medium);
			display: flex;
			align-items: center;
		}
	}

	.ttt-schedule{
		position: relative;
		color: $white;

		.container{
			position: relative;
			z-index: 10;

			@include breakpoint(medium){
				@include grid(2fr 1fr);
			}

			h1{
				color: $white;
				@include breakpoint(medium){
					padding-right: 50px;
				}
				@include breakpoint(large){
					padding-right: 140px;
				}
			}
			h2.dates{
				color: $white;
			}
			.button{
				margin-bottom: 0;
				background: transparent;
				border: 2px dotted $white;
				&:hover{
					background: $yellow;
					color: $black;
					border-color: $yellow;
				}
			}
		}
		.overlay{
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background: rgba(#297dbd, 0.9);

			img{
				position: relative;
				height: 100%;
				width: 100%;
				object-fit: cover;
				z-index: -1;
			}
		}
	}

	.bce-schedule{
		@include breakpoint(medium){
			@include grid(2fr 1fr);
		}

		h1{
			color: $pink;
			@include breakpoint(large){
				padding-right: 130px;
			}
		}

		.button{
			margin-bottom: 0;
			background: $white;
			color: $black;
			border: 2px dotted $black;

			&:hover{
				background: $pink;
				color: $white;
				border-color: $pink;
			}
		}
	}
}