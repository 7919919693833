.main-footer{
	box-shadow: inset 0 20px 20px -20px rgba(190,190,190,0.8);
	.container{
		padding: 60px;
		@include breakpoint(medium){
			@include grid(1fr 1fr 1fr);
			padding: 30px;
		}

		@include breakpoint(large){
			padding: 54px 0 40px 60px;
		}

		@include breakpoint(xlarge){
			padding: 54px 0px 40px 0px;
		}

		.legal{
			margin-bottom: 20px;
			@include breakpoint(medium){
				margin-bottom: 0;
			}
		}
	}
}