// Foundation for Sites by ZURB
// foundation.zurb.com
// Licensed under MIT Open Source

/// Creates a CSS triangle, which can be used for dropdown arrows, dropdown pips, and more. Use this mixin inside a `&::before` or `&::after` selector, to attach the triangle to an existing element.
/// Direction the triangle points. Can be `up`, `right`, `down`, or `left`.
@mixin css-triangle(
  $triangle-size,
  $triangle-color,
  $triangle-direction
) {
  display: block;
  width: 0;
  height: 0;

  border: inset $triangle-size;

  content: '';

  @if ($triangle-direction == down) {
    border-bottom-width: 0;
    border-top-style: solid;
    border-color: $triangle-color transparent transparent;
  }
  @if ($triangle-direction == up) {
    border-top-width: 0;
    border-bottom-style: solid;
    border-color: transparent transparent $triangle-color;
  }
  @if ($triangle-direction == right) {
    border-right-width: 0;
    border-left-style: solid;
    border-color: transparent transparent transparent $triangle-color;
  }
  @if ($triangle-direction == left) {
    border-left-width: 0;
    border-right-style: solid;
    border-color: transparent $triangle-color transparent transparent;
  }
}

/// Creates a menu icon with a set width, height, number of bars, and colors. The mixin uses the height of the icon and the weight of the bars to determine spacing. <div class="docs-example-burger"></div>
@mixin hamburger(
  $color: $black,
  $color-hover: $dark-gray,
  $width: 20px,
  $height: 16px,
  $weight: 2px,
  $bars: 3
) {
  // box-shadow CSS output
  $shadow: ();
  $hover-shadow: ();

  // Spacing between bars is calculated based on the total height of the icon and the weight of each bar
  $spacing: ($height - ($weight * $bars)) / ($bars - 1);

  @if unit($spacing) == 'px' {
    $spacing: floor($spacing);
  }

  @for $i from 2 through $bars {
    $offset: ($weight + $spacing) * ($i - 1);
    $shadow: append($shadow, 0 $offset 0 $color, comma);
  }

  // Icon container
  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: $width;
  height: $height;
  cursor: pointer;

  // Icon bars
  &::after {
    position: absolute;
    top: 0;
    left: 0;

    display: block;
    width: 100%;
    height: $weight;

    background: $color;
    box-shadow: $shadow;

    content: '';
  }

  // Hover state
  @if $color-hover {
    // Generate CSS
    @for $i from 2 through $bars {
      $offset: ($weight + $spacing) * ($i - 1);
      $hover-shadow: append($hover-shadow, 0 $offset 0 $color-hover, comma);
    }

    &:hover::after {
      background: $color-hover;
      box-shadow: $hover-shadow;
    }
  }
}