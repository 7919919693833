.checkout{
	padding: $global-mobile-padding;
	@include breakpoint(large){
		padding: $global-page-padding;
	}

	input, select{
		padding: 12px 0 12px 20px;
		font-size: rem-calc(14);
		margin-bottom: 16px;
		@include breakpoint(large){
			font-size: rem-calc(18);
			margin-bottom: 0;
		}
	}

	select{
		background: $light-gray;
		border-radius: 4px;
	}

	.locale{
		@include breakpoint(large){
			.zip{width: 30%;}
			.country{width: 40%;}
		}
	}

	.billing{
		.dropdown-title{
			display: flex;
			margin: 60px 0 0;
			align-items: center;
			h4{
				margin: 0;
			}
			i{
				font-size: rem-calc(30);
				margin-left: 20px;
			}
		}
	}

	.order-details{
		margin-top: 60px;

		.primary-color{
			color: $primary-color;
		}
	}

	textarea{
		height: 125px;
	}
	
	button[type=submit]{
		border-color: $primary-color;
		margin-bottom: 50px;

		&:hover{
			background: $green;
		}
	}

	.notice{
		@include grid-container();
		background: scale-color($primary-color, $lightness: 90%);
		padding: 16px;
		border-radius: 8px;
	}
}