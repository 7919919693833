.page-bce{
	.overview{
		padding: 60px 40px 20px;
		font-size: rem-calc(18);
		line-height: rem-calc(30);
		text-align: center;
		@include breakpoint(large){
			text-align: left;
			padding: 80px 80px 0;
		}
		@include breakpoint(xlarge){
			padding: 80px 0 0;
		}
	}
	.bce-details{
		background: #f8eaf1;
		margin-top: 50px;

		header{
			background: $pink;
			padding: rem-calc(10 28);
			h2{
				color: $white;
				font-size: rem-calc(20);
				margin: 0;
			}
		}

		.content{
			padding: 40px;

			@include breakpoint(large){
				@include grid(1fr 1fr, 30px);
			}

			.column{
				h1{
					font-size: rem-calc(22);
					color: $pink;
				}
				h3{
					font-size: rem-calc(18);
					color: $pink;
				}
				img{
					display: block;
					margin: 0 auto 25px;
				}
				strong{
					font-weight: 600;
				}
			}
		}

		footer{
			grid-column-start: 1;
  			grid-column-end: 3;
  			font-weight: 600;
  			font-style: italic;
		}
	}
	.testimonials{
		padding: 60px 40px;
		text-align: center;

		@include breakpoint(large){
			margin-bottom: 80px;
			padding: 80px;
			text-align: left;
		}

		@include breakpoint(xlarge){
			padding: 0;
		}

		.quote{
			font-weight: bold;
			font-style: italic;
			font-size: rem-calc(14);
			line-height: rem-calc(26);
			margin-bottom: 20px;
		}
	}
}