// Foundation for Sites by ZURB
// foundation.zurb.com
// Licensed under MIT Open Source

////
/// @group forms
////

$fieldset-border: 0 !default;
$fieldset-padding: rem-calc(20) !default;
$fieldset-margin: rem-calc(0 0 16) !default;
$fieldset-input-margin: rem-calc(16) !default;
$legend-padding: rem-calc(0 3) !default;

@mixin fieldset {
  margin: $fieldset-margin;
  padding: $fieldset-padding;
  border: $fieldset-border;
  

  @include breakpoint(large){
    display: flex;
  }

  input, select{
    margin-bottom: 0;
  }
  
  input, .select{
    margin-right: $fieldset-input-margin;

    &:last-child{
      margin-right: 0;
    }
  }
  label{
    width: 100%;
  }
  legend {
    // Covers up the fieldset's border to create artificial padding
    margin: 0;
    margin-left: rem-calc(-3);
    padding: $legend-padding;
  }
}

@mixin foundation-form-fieldset {
  fieldset {
    margin: 0;
    padding: 0;
    border: 0;
  }

  legend {
    max-width: 100%;
    margin-bottom: $form-spacing * 0.5;
  }

  .fieldset {
    @include fieldset;
  }
}
